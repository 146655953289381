import React from 'react'
import ACC_THEME from '../themes'
import ACC_DARK_THEME from '../themes/dark'
import GoogleStructuredData from '../components/blok/google-structured-data'

const formatTheme = (themeValues) => {
  switch (themeValues.themeSetting) {
    case 'dark':
    case 'Dark':
      return ACC_DARK_THEME
    case 'default':
    case 'Default':
    default:
      return ACC_THEME
  }
}

const formatCourseData = (values) => {
  return <GoogleStructuredData type="Course" data={values} />
}

const formatters = {
  theme: formatTheme,
  coursesData: formatCourseData,
}

const configValues = (configurations) => {
  var formattedConfigs = { theme: null, coursesData: null }
  !!configurations &&
    configurations.length > 0 &&
    configurations.forEach((config) => {
      const newConfig = { ...config }

      delete newConfig.component
      delete newConfig._editable

      if (formatters[config.component])
        formattedConfigs[config.component] = formatters[config.component]({
          ...newConfig,
        })
    })
  return formattedConfigs
}

export default configValues

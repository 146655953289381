import React, { useState, useEffect, useContext } from 'react'
import Layout from '../layout'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import renderBloks from '@renderBloks'
import renderBlok from '@renderBlok'
import ACC_THEME from '@themes'
import PageValidationForm from '@helpers/page-validation-form'
import PageContext from '@context'
import configValues from '@helpers/config-values'
import { useExitIntent } from 'use-exit-intent'
import { Modal } from '@system'
import get from 'lodash/get'
import { Box } from '@material-ui/core'
const Page = (props, location) => {
  const {
    metaData,
    passwords,
    passwordProtect,
    body,
    canonicalPage,
    noIndex,
    conversionType,
    _uid,
    config,
    exitIntentModal,
    headerImage,
  } = props.blok
  const [isPasswordProtected, setIsPasswordProtected] =
    useState(passwordProtect)

  const popupModal = get(exitIntentModal, '[0]', {})
  const content = popupModal.content

  const modalForm = get(
    popupModal.content?.filter(
      (blok) => blok.component === 'marketoFormModule'
    ),
    '[0]',
    {}
  )
  const { title, smallerWidth } = modalForm

  const [shouldOpenModal, toggleModal] = useState(true)

  const { unsubscribe, isTriggered, registerHandler } = useExitIntent({
    cookie: {
      key: popupModal.cookieKey,
      daysToExpire: parseInt(Number(popupModal.daysToExpire)),
    },

    desktop: {
      delayInSecondsToTrigger: popupModal.delayInSecondsToTriggerDesktop,
      triggerOnIdle: popupModal.triggerOnIdleDesktop,
      triggerOnMouseLeave: popupModal.triggerOnMouseLeaveDesktopOnly,
    },

    mobile: {
      delayInSecondsToTrigger: popupModal.delayInSecondsToTriggerMobile,
      triggerOnIdle: popupModal.triggerOnIdleMobile,
    },
  })

  const { theme, coursesData } = configValues(config)
  const updateIsPasswordProtected = (props) => {
    setIsPasswordProtected(props)
  }
  const isInEditor = useContext(PageContext).isInEditor
  const context = useContext(PageContext)
  const succesConversionType =
    !!context.location &&
    context.location.state &&
    context.location.state.conversionType

  useEffect(() => {
    if (succesConversionType && !isInEditor) {
      window.mutiny = window.mutiny || []
      window.mutiny?.client?.trackConversion({ name: 'page-success' })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'page.content',
        'page.content': {
          v: 11,
          type: 'paid conversion',
          convType: succesConversionType || conversionType || 'generic',
          id: _uid,
          name: metaData.title,
          url: document.URL,
        },
      })
    }
  }, [_uid, conversionType, isInEditor, metaData, succesConversionType])

  registerHandler({
    id: 'myHandlerId',
    handler: () => {},
  })
  registerHandler({
    id: 'onUnsubscription',
    handler: () => {},
    context: ['onUnsubscribe'],
  })
  const handleClose = (e) => {
    toggleModal(false)
    unsubscribe()
  }
  return (
    <Layout metaData={metaData} canonicalPage={canonicalPage} noIndex={noIndex}>
      {!!coursesData && coursesData}
      <ThemeProvider theme={theme || ACC_THEME}>
        <CssBaseline />
        {!!props.redirectModal && renderBlok(props.redirectModal)}
        {isPasswordProtected && passwords.length > 0 ? (
          <PageValidationForm
            passwords={passwords}
            updateIsPasswordProtected={updateIsPasswordProtected}
          />
        ) : (
          <Box>
            {!!headerImage &&
              headerImage.length > 0 &&
              renderBloks(headerImage)}
            {!!body && !!body.length > 0 && renderBloks(body)}
          </Box>
        )}
        {!!exitIntentModal && exitIntentModal.length > 0 && !!isTriggered && (
          <Modal
            id="myHandlerId"
            open={shouldOpenModal}
            handleClose={handleClose}
            title={title}
            smallerWidth={smallerWidth}
            modalIsDark={popupModal.modalIsDark}
            isCustomSize={popupModal.isCustomSize}
            customSize={popupModal.customSize}
            meetupDetails={props.meetupDetails}
          >
            {!!exitIntentModal &&
              renderBloks(content, {
                isModalContent: true,
                hiddenFields: props.hiddenFields,
                modalCookie: popupModal.cookieKey,
                modalCookieExpire: popupModal.daysToExpire,
              })}
          </Modal>
        )}
      </ThemeProvider>
    </Layout>
  )
}

export default Page
